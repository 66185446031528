import React from 'react';
import { Modal } from 'components/modal/Modal';
import { Button, Container, Flex, Text, Link, List } from 'basis';
import { OverviewSources } from '_config';
import { getRatesAndFees } from 'featureToggles';

export const GoFeaturesAndBenefits = ({ isOpen, onClose, getLinkWithAnalytics, trackEventSender }) => {
  const { purchaseAnnualRate, cashAnnualRate, expiredPromotionalPurchaseRate, monthlyFee } = getRatesAndFees();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container padding="6" padding-md="8">
        <Container padding="0 0 4">
          <Flex placeItems="left center">
            <Text textStyle="heading6" textStyle-md="heading4">
              Credit card features and benefits
            </Text>
          </Flex>
        </Container>
        <Container>
          Please ensure the{' '}
          <Link
            href={getLinkWithAnalytics(OverviewSources.AU.latitude_go_card)}
            newTab
            onClick={() =>
              trackEventSender({
                location: 'landing',
                label: OverviewSources.AU.latitude_go_card.label,
              })
            }
          >
            {OverviewSources.AU.latitude_go_card.label}
          </Link>{' '}
          credit card is right for you.
        </Container>
        <Container padding="6 0" margin="4 0 0 0">
          <List>
            <List.Item>
              <Text>
                <strong>Interest free offers available from 6 to 60 months</strong> at thousands of Australia's leading{' '}
                retailers
              </Text>
            </List.Item>
            <List.Item>
              <Text>
                <strong>Enjoy 6 months interest free</strong> on your everyday purchases of $250 or more. Select your
                purchases in the Latitude app within 30 days.{' '}
                <Link
                  newTab
                  href={getLinkWithAnalytics(OverviewSources.AU.interest_free_detail)}
                  onClick={() =>
                    trackEventSender({
                      location: 'landing',
                      label: OverviewSources.AU.interest_free_detail.label,
                    })
                  }
                >
                  {OverviewSources.AU.interest_free_detail.label}
                </Link>{' '}
              </Text>
            </List.Item>
            <List.Item>
              <Text>
                <strong>Everyday credit card</strong> with a wide range of payment options that suits every budget size
              </Text>
            </List.Item>
            <List.Item>
              <Text>
                <strong>Up to 55 days interest free</strong> on everyday purchases<sup>1</sup>
              </Text>
            </List.Item>
            <List.Item>
              <Text>
                <strong>Interest Rates</strong> - {purchaseAnnualRate?.toFixed(2)}% p.a. interest rate on everyday{' '}
                purchases, {cashAnnualRate?.toFixed(2)}% p.a. on cash advances and{' '}
                {expiredPromotionalPurchaseRate.toFixed(2)}% p.a on expired Interest Free Payment Plans
              </Text>
            </List.Item>
            <List.Item>
              <strong>Account Fee</strong> - ${monthlyFee.toFixed(2)} monthly account service fee (see other{' '}
              <Link
                newTab
                href={getLinkWithAnalytics({
                  label: 'Rates and Fees',
                  url: OverviewSources.AU.latitude_go_card.url,
                })}
                onClick={() =>
                  trackEventSender({
                    location: 'landing',
                    label: 'Rates and Fees',
                  })
                }
              >
                Rates and Fees
              </Link>{' '}
              )
            </List.Item>
            <List.Item>
              <strong>Safe and secure</strong> - Shop knowing your card and account are protected by stringent security{' '}
              systems
            </List.Item>
            <List.Item>
              <strong>Before making a decision, please read the</strong>{' '}
              <Link
                newTab
                href={OverviewSources.AU.go_key_facts_sheet.url}
                onClick={() =>
                  trackEventSender({
                    location: 'landing',
                    label: OverviewSources.AU.go_key_facts_sheet.label,
                  })
                }
              >
                {OverviewSources.AU.go_key_facts_sheet.label}
              </Link>{' '}
              and{' '}
              <Link
                newTab
                href={OverviewSources.AU.go_conditions_of_use.url}
                onClick={() =>
                  trackEventSender({
                    location: 'landing',
                    label: OverviewSources.AU.go_conditions_of_use.label,
                  })
                }
              >
                {OverviewSources.AU.go_conditions_of_use.label}
              </Link>{' '}
              to ensure you're aware of all the fees, charges and interest rates.
            </List.Item>
          </List>
        </Container>
        <Container padding="4 0">
          <Text>
            <sup>1</sup>To take advantage of up to 55 days interest free on everyday credit card purchases, you need to{' '}
            pay the full closing balance (excluding un-expiring interest payment plans) on each statement of account by{' '}
            the applicable due date.
          </Text>
        </Container>

        <Flex placeItems="center">
          <Container padding="2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};
