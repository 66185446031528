import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid, Text, useBreakpoint, Message, List, Link } from 'basis';
import { useTheme as emotionUseTheme } from '@emotion/react';
import { LayoutPage, Form, Errors, ButtonToggle } from 'components';
import { useStoreValue, APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR } from 'store';
import { submitHandler, getDataLayerElements, getProductInfo, updateDataDogContext } from 'utils';
import { useEventTracking } from 'react-event-tracker';
import { APPLY_BTN_LABEL, OverviewSources } from '_config/_constants';
import Au28DBanner from 'images/banner/28d-banner.png';
import Au28DBannerMobile from 'images/banner/28d-banner-mobile.png';
import { STEP_CONTACT_DETAILS } from '_config';
import {
  useInitApplication,
  useErrorTrackingAndUpdateStore,
  useFormInitialValues,
  useDataLayerAndAnalyticsLinks,
} from 'hooks';
import { Banner, BannerImage, ContentWrapper } from './LandingPage28D.styles';
import { FeaturesAndBenefitsModal } from './FeaturesAndBenefitsModal';
import { HeaderBannerTextArea } from './HeaderBannerTextArea';
import { useAdblockDetector } from '../../hooks/useAdblockDetector';
import { StartApplicationButton } from './StartApplicationButton';
import { DEV_FEATURES } from '../../__dev';
import { LandingContentItem } from '../landing-page-dj/LandingPageDJ.styles';
import ProfileIcon from '../../images/icons/profile-single.svg';
import HomeIcon from '../../images/icons/home.svg';
import WalletIcon from '../../images/icons/finance-wallet.svg';
import MoneyIcon from '../../images/icons/finance-pay-manual.svg';
import IdentityIcon from '../../images/icons/identity.svg';
import StopwatchIcon from '../../images/icons/stopwatch.svg';
import { VerifierModal } from '../landing-page-dj/VerifierModal';
import { DeclarationModal } from '../landing-page-dj/DeclarationModal';
import { PrivacyNoticeModal } from './PrivacyNoticeModal';

const FORM_ID = 'overview';

const formTealiumLocation = 'landing';
const pageTealiumLocation = 'what-to-expect';

export function LandingPage28D() {
  const theme = emotionUseTheme();
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [verifierModalOpen, setVerifierModalOpen] = useState(false);
  const [declarationModalOpen, setDeclarationModalOpen] = useState(false);
  const [privacyNoticeModalOpen, setPrivacyNoticeModalOpen] = useState(false);

  const [storeState, updateStore] = useStoreValue();
  const { trackEvent, getQueryString } = useEventTracking();
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const initialValues = useFormInitialValues(FORM_ID);
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const [isAdblockCheckComplete, isAdblockerDetected] = useAdblockDetector(
    `//tags.tiqcdn.com/utag/latitudefs/apply-funnel/${process.env.REACT_APP_TEALIUM_ENV}/utag.sync.js`,
  );
  const productName = getProductInfo().name;

  useEffect(() => {
    window.DD_RUM?.startSessionReplayRecording();
  }, []);

  const handleSubmitSuccess = data => {
    const updates = {
      applicationRef: data.applicationRef,
      applicationId: data.applicationId,
      isResuming: false,
    };

    trackEventSender(
      {
        category: 'application',
        action: 'application-started',
        location: formTealiumLocation,
        label: APPLY_BTN_LABEL,
      },
      updates,
    );

    updateDataDogContext(updates);

    updateStore({
      activeStep: STEP_CONTACT_DETAILS,
      ...updates,
    });

    history.push(STEP_CONTACT_DETAILS);
  };

  const { submit, loading } = useInitApplication({
    onSuccess: handleSubmitSuccess,
    onError: handleErrorCallback,
  });

  const onSubmit = ({ errors }) => {
    function initialiseApplication() {
      const applicationStartDate = new Date().toISOString();
      const newAcquisition = {
        ...storeState.acquisition,
        overview: {
          consentGiven: true,
        },
      };
      updateStore({ applicationStartDate, acquisition: newAcquisition });
      submit(newAcquisition.overview);
    }
    submitHandler({ submit: initialiseApplication, errors });
  };

  const retrySubmit = () => {
    submit(storeState.acquisition.overview);
  };

  const openFeatureAndBenefitsModal = () => {
    setModalOpen(true);
    trackEvent({
      event: {
        category: 'text-link',
        action: 'interaction',
        location: pageTealiumLocation,
        label: 'View features and benefits',
      },
      ...getDataLayerElements(storeState),
    });
  };

  const closeFeatureAndBenefitsModal = () => {
    setModalOpen(false);
  };
  const openVerifierModal = () => {
    setVerifierModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: "Verifier's terms of service",
    });
  };

  const closeVerifierModal = () => {
    setVerifierModalOpen(false);
  };

  const openDeclarationModal = () => {
    setDeclarationModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: 'Declaration and Credit Application',
    });
  };

  const closeDeclarationModal = () => {
    setDeclarationModalOpen(false);
  };

  const openPrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: 'Privacy Notice',
    });
  };

  const closePrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(false);
  };

  const getLinkWithAnalytics = key => {
    const gemid2 = getQueryString({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location: 'what-to-expect',
        label: key.label,
      },
    });

    const link = new URL(key.url);
    link.searchParams.set('gemid2', gemid2);
    return link.href;
  };

  if (storeState.applicationErrors) {
    const retry = [APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR].includes(storeState.applicationErrors.type)
      ? retrySubmit
      : null;
    return (
      <LayoutPage hideBackButton>
        <Errors retry={retry} retrying={loading} isPreSubmission />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage noSessionTimeout={!storeState.acquisition.overview.consentGiven} hideBackButton noChildrenPadding>
      <FeaturesAndBenefitsModal
        trackEventSender={trackEventSender}
        getLinkWithAnalytics={getLinkWithAnalytics}
        isOpen={modalOpen}
        onClose={closeFeatureAndBenefitsModal}
      />
      <VerifierModal isOpen={verifierModalOpen} onClose={closeVerifierModal} />
      <DeclarationModal isOpen={declarationModalOpen} onClose={closeDeclarationModal} />
      <PrivacyNoticeModal isOpen={privacyNoticeModalOpen} onClose={closePrivacyNoticeModal} />

      <Banner>
        <Container width="100%" padding="0" padding-lg="0">
          <Grid preset="page">
            <Grid.Item colSpan="all" colSpan-sm="all" colSpan-xl="8-13" colSpan-lg="7-13" rowSpan-md="0-2">
              <Container>
                <BannerImage theme={theme}>
                  <img src={['lg', 'xl'].includes(breakpoint) ? Au28DBanner : Au28DBannerMobile} alt="LFrame card" />
                </BannerImage>
              </Container>
            </Grid.Item>

            <Grid.Item colSpan="all" colSpan-sm="all" colSpan-lg="0-7" rowSpan-lg="0-2">
              <HeaderBannerTextArea openFeatureAndBenefitsModal={openFeatureAndBenefitsModal} />
            </Grid.Item>
          </Grid>
        </Container>
      </Banner>
      <Container padding="0 5 5 5" paddingMd="0">
        <Form id={FORM_ID} initialValues={initialValues} onSubmit={onSubmit} hideFormButtons disableFormCache>
          {formData => {
            return (
              <ContentWrapper theme={theme}>
                <Container bg="white" margin="auto">
                  <Grid colsGutter="0" cols="6" rowsGap="4" rowsGap-md="10">
                    <Left rowIndexMd={0} rowIndexSm={0}>
                      <Text textStyle="heading4" as="h4">
                        Who can apply?
                      </Text>
                    </Left>
                    <Right rowIndexMd={0} rowIndexSm={1}>
                      <LandingContentItem>
                        <img src={ProfileIcon} alt="profile-icon" />
                        <Text as="p">You must be aged 18 or older</Text>
                      </LandingContentItem>
                      <LandingContentItem>
                        <img src={HomeIcon} alt="house-icon" />
                        <Text>
                          You must be a resident of Australia or a temporary resident who legally has the right to work
                          in Australia (and intends to stay) for at least the next 12 months
                        </Text>
                      </LandingContentItem>
                    </Right>
                    <Left rowIndexMd={1} rowIndexSm={2}>
                      <Text textStyle="heading4" as="h4">
                        Details to have handy
                      </Text>
                    </Left>
                    <Right rowIndexMd={1} rowIndexSm={3}>
                      <LandingContentItem>
                        <img src={MoneyIcon} alt="money-icon" />
                        <Text>Income, debts and expenses Information</Text>
                      </LandingContentItem>
                      <LandingContentItem>
                        <img src={IdentityIcon} alt="id-icon" />
                        <Text as="p">Proof of ID (Australian Driver's Licence, Medicare or Passport)</Text>
                      </LandingContentItem>
                      <LandingContentItem>
                        <img src={WalletIcon} alt="wallet-icon" />
                        <Text as="p">Proof of Income (such as payslips, bank statements, tax statements)</Text>
                      </LandingContentItem>
                    </Right>
                    <Left rowIndexMd={2} rowIndexSm={4}>
                      <Text textStyle="heading4" as="h4">
                        How long will it take?
                      </Text>
                    </Left>
                    <Right rowIndexMd={2} rowIndexSm={5}>
                      <LandingContentItem>
                        <img src={StopwatchIcon} alt="money-icon" />
                        <Text>
                          You will need 10-15 minutes to complete the application (If we need to review additional
                          information, we need up to 2 business days)
                        </Text>
                      </LandingContentItem>
                    </Right>
                    <Left rowIndexMd={3} rowIndexSm={6}>
                      <Text textStyle="heading4">Please make sure this product is right for you</Text>
                    </Left>
                    <Right rowIndexMd={3} rowIndexSm={7}>
                      <Container margin="0 0 8 0">
                        <Text>
                          Before we begin your application, we need to ask you a question to confirm the {productName}{' '}
                          is right for you.
                        </Text>
                      </Container>
                      <Container margin="0 0 3 0">
                        <strong>Are you after a credit card that gives you:</strong>
                      </Container>
                      <Container margin="0 0 3 0">
                        <List>
                          <List.Item>
                            the ability to make everyday credit card purchases in-store and online at businesses who
                            accept Mastercard<sup>®</sup> and perform other transactions.
                          </List.Item>
                          <List.Item>
                            pay no international transaction or currency conversion fees on purchases; and
                          </List.Item>
                          <List.Item>
                            access to a Rewards program to earn redeemable amounts to purchase an e-Gift card on
                            eligible transactions within a statement period?
                          </List.Item>
                        </List>
                      </Container>
                      <Container margin="8 0" margin-md="0">
                        <ButtonToggle
                          name="consentGiven"
                          options={[
                            {
                              label: 'No',
                              value: 'no',
                            },
                            {
                              label: 'Yes',
                              value: 'yes',
                            },
                          ]}
                          testId="landing-consent-toggle"
                        />
                      </Container>
                    </Right>
                    {formData.state.values.consentGiven === 'yes' && (
                      <>
                        <Left rowIndexMd={4} rowIndexSm={8}>
                          <Text textStyle="heading4">By starting this application you agree:</Text>
                        </Left>
                        <Right rowIndexMd={4} rowIndexSm={9}>
                          <List>
                            <List.Item>
                              <Link
                                href={getLinkWithAnalytics(OverviewSources.AU.latitude_28d_mastercard)}
                                newTab
                                onClick={() => trackEventSender(OverviewSources.AU.latitude_28d_mastercard.label)}
                              >
                                {OverviewSources.AU.latitude_28d_mastercard.label}
                              </Link>{' '}
                              meets your key credit card requirements.
                            </List.Item>
                            <List.Item>
                              You have read, understood and agree with the{' '}
                              <Link onClick={openPrivacyNoticeModal}>Privacy Notice</Link>,{' '}
                              <Link
                                href={getLinkWithAnalytics(OverviewSources.AU.twenty_eight_degrees_key_facts_sheet)}
                                newTab
                                onClick={() =>
                                  trackEventSender(OverviewSources.AU.twenty_eight_degrees_key_facts_sheet.label)
                                }
                              >
                                {OverviewSources.AU.twenty_eight_degrees_key_facts_sheet.label}
                              </Link>{' '}
                              and{' '}
                              <Link
                                href={getLinkWithAnalytics(OverviewSources.AU.twenty_eight_degrees_conditions_of_use)}
                                newTab
                                onClick={() =>
                                  trackEventSender(OverviewSources.AU.twenty_eight_degrees_conditions_of_use.label)
                                }
                              >
                                {OverviewSources.AU.twenty_eight_degrees_conditions_of_use.label}
                              </Link>{' '}
                              and <Link onClick={openDeclarationModal}>Declaration and Credit Application</Link>. These
                              documents explain how your personal information is used, the product's features and terms
                              under which this product operates.
                            </List.Item>
                            <List.Item>To let us complete a credit check.</List.Item>
                            <List.Item>
                              To electronically receive your credit contract as set out in the Financial Table and
                              Conditions of Use.
                            </List.Item>
                            <List.Item>
                              To electronically verify your income using a secure process with Verifier (see{' '}
                              <Link onClick={openVerifierModal}>Verifier's terms of service</Link>). If your income
                              can't be verified this way, we will provide alternatives
                            </List.Item>
                          </List>
                        </Right>
                        <Right rowIndexMd={5} rowIndexSm={10}>
                          <StartApplicationButton
                            backgroundColor={theme.newStageButtonColor}
                            disabled={
                              isAdblockCheckComplete && isAdblockerDetected && !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP
                            }
                            loading={loading}
                            theme={theme}
                          >
                            {APPLY_BTN_LABEL}
                          </StartApplicationButton>
                        </Right>
                      </>
                    )}
                    {formData.state.values.consentGiven === 'no' && (
                      <>
                        <Left rowIndexMd={4} rowIndexSm={8}>
                          <Text textStyle="heading4" as="h4">
                            Looks like this product isn't right for you
                          </Text>
                        </Left>
                        <Right rowIndexMd={4} rowIndexSm={9}>
                          <LandingContentItem>
                            <div>
                              <Text>Unfortunately, the product you have selected does not meet your needs.</Text>
                              <Text>
                                For more information or access to other products, please visit{' '}
                                <Link
                                  href="https://www.latitudefinancial.com.au"
                                  newTab
                                  onClick={() => {
                                    trackEventSender({
                                      category: 'application',
                                      action: 'application-started',
                                      location: formTealiumLocation,
                                      label: 'latitude home page',
                                    });
                                  }}
                                >
                                  latitudefinancial.com.au
                                </Link>
                              </Text>
                            </div>
                          </LandingContentItem>
                        </Right>
                      </>
                    )}
                    <Right rowIndexMd={6} rowIndexSm={11}>
                      <Container>
                        {isAdblockCheckComplete && isAdblockerDetected && !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP && (
                          <Message severity="stop" bg="secondary.pink.t30">
                            Please turn off your adblocker for the application to work and refresh your browser once it
                            is done.
                          </Message>
                        )}
                      </Container>
                    </Right>
                  </Grid>
                </Container>
              </ContentWrapper>
            );
          }}
        </Form>
      </Container>
    </LayoutPage>
  );
}

function Left({ rowIndexMd, rowIndexSm, children }) {
  return (
    <Grid.Item colSpan="all" colSpan-md="0-1" rowSpan={rowIndexSm} rowSpan-md={rowIndexMd} margin-md="0">
      <Container padding-md="0 4 0 0" padding-lg="0 11 0 0">
        {children}
      </Container>
    </Grid.Item>
  );
}

function Right({ rowIndexMd, rowIndexSm, children }) {
  return (
    <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan={rowIndexSm} rowSpan-md={rowIndexMd}>
      {children}
    </Grid.Item>
  );
}
