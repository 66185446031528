import React from 'react';
import { Link, List, Text } from 'basis';
import { useEventTracking } from 'react-event-tracker';
import { useStoreValue } from 'store';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import { OverviewSources } from '_config/_constants/externalLinks';

import groupLFrameCardImage from 'images/premiere-card.png';
// import groupLFrameCardImageMobile from 'images/premiere-card.png';
import groupLFramePrestigeCardImage from 'images/prestige-card.png';
// import groupLFramePrestigeCardImageMobile from 'images/prestige-card.png';
import groupLFrameCardImageNZ from 'images/hero-NZ.png';
import groupLFrameCardImageMobileNZ from 'images/hero-mobile-NZ.png';

const groupLFrameCardImageMobile = groupLFrameCardImage;
const groupLFramePrestigeCardImageMobile = groupLFramePrestigeCardImage;

export const OverviewModalContent = {
  title: 'Gem Visa - Features and benefits',
  body: [
    {
      subtitle: '6 months Interest Free*',
      description: 'Enjoy 6 months Interest Free* on everyday purchases of $250 or more.',
    },
    {
      subtitle: 'Up to 55 days Interest Free*',
      description: 'Get up to 55 days Interest Free* every time you spend below $250 with your Gem Visa, worldwide.',
    },
    {
      subtitle: 'Long term Gem Visa deals',
      description:
        'We’ve joined with some of New Zealand’s leading brands so you can enjoy long term interest free deals.',
    },
    {
      subtitle: 'Start shopping straight away',
      description:
        'Once approved you can use your Gem Visa account number to access long term interest free deals online or in-store at participating retailers. Or alternatively download the Latitude mobile app and add your Gem Visa into your digital wallet.',
    },
  ],
};

export const OverviewModalContentTwentyEightDegrees = {
  title: 'Latitude 28° Global Platinum Mastercard® benefits',
  body: [
    'No international transaction or currency conversion fees on purchases online and overseas. T&Cs, fees and charges apply.',
    'Great foreign exchange rates set by our partners at Mastercard® so you get foreign currencies at the same low rate we do.',
    'Spend on everyday offers - Credit back offers, discounts and deals on big brands at home and abroad.',
    'Small comforts with free Flight Delay Pass - Food, drinks and free Wi-Fi in a selection of airport lounges if your flight is delayed 2+ hours. T&Cs apply.',
    'Stay in touch with free Global Data Roaming with Flexiroam - 3GB free data each calendar year & 15% off any extra data purchased in over 150 countries with Flexiroam. T&Cs apply.',
    'Fraud protection for peace of mind.',
    'Add an additional cardholder at no extra fee.',
  ],
};

export const headerTextDavidJonesPrestige = 'Apply for a David Jones Prestige credit card';
export const headerTextDavidJonesPremiere = 'Apply for a David Jones Premiere credit card';
export const OverviewContent = {
  AU: {
    headerText: 'David Jones Premiere',
    headerIntroText: '',
    formHeader: 'Before you start your application',
    atLeast18: 'Residents of Australia, who are at least 18 years old',
    residentInfo: 'Temporary residents need to hold a visa that’s valid for at least the next 12 months',
    passportOrDriverLicence: 'e.g. Driver licence, Medicare card, Passport',
    proofOfIncome: 'e.g. Sharing your bank statement online or uploading documents',
    showTermsAndConditionsCheckbox: true,
    showLivingExpensesAndUtilitiesInfo: true,
    groupLFrameCardImage,
    groupLFrameCardImageMobile,
    groupLFramePrestigeCardImage,
    groupLFramePrestigeCardImageMobile,
    productInfo: <ProductInfo country="AU" />,
  },
  NZ: {
    headerText: 'Shop better with',
    headerTextLine2: 'Gem Visa credit card',
    headerIntroText: 'Make it yours with a Gem Visa',
    formHeader: 'Before you start your application',
    atLeast18: 'You must be at least 18 years old',
    validInfo: 'Have a valid email address and NZ mobile number',
    residentInfo: 'Be a citizen or reside in New Zealand (for at least the next 12 months)',
    passportOrDriverLicence: 'E.g. NZ drivers licence, NZ Passport, International Passport',
    proofOfIncome: 'E.g. sharing your bank statement online or uploading documents',
    showLivingExpensesAndUtilitiesInfo: true,
    groupLFrameCardImage: groupLFrameCardImageNZ,
    groupLFrameCardImageMobile: groupLFrameCardImageMobileNZ,
    productInfo: <ProductInfo country="NZ" />,
  },
};

function ProductInfo({ country }) {
  const [states] = useStoreValue();
  const { trackEvent, getQueryString } = useEventTracking();

  const trackEventSender = label => {
    trackEvent({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location: 'what-to-expect',
        label,
      },
      ...getDataLayerElements(states),
    });
  };

  const getLinkWithAnalytics = key => {
    const gemid2 = getQueryString({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location: 'what-to-expect',
        label: key.label,
      },
    });

    const link = new URL(key.url);
    link.searchParams.set('gemid2', gemid2);
    return link.href;
  };
  if (country === 'NZ') {
    const initialDisclosureStatement = OverviewSources.NZ.initial_disclosure_statement_FY2023_Q3;
    return (
      <List>
        <List.Item>
          Read our{' '}
          <Link
            href={getLinkWithAnalytics(OverviewSources.NZ.privacy_acknowledgement_and_authorisation)}
            newTab
            onClick={() => trackEventSender(OverviewSources.NZ.privacy_acknowledgement_and_authorisation.label)}
          >
            {OverviewSources.NZ.privacy_acknowledgement_and_authorisation.label}
          </Link>{' '}
          statement about how we will use the information we collect in this application process
        </List.Item>
        <List.Item>
          Read and understood that the{' '}
          <Link
            href={getLinkWithAnalytics(OverviewSources.NZ.gem_visa_conditions_of_use)}
            newTab
            onClick={() => trackEventSender(OverviewSources.NZ.gem_visa_conditions_of_use.label)}
          >
            {OverviewSources.NZ.gem_visa_conditions_of_use.label}
          </Link>{' '}
          and{' '}
          <Link
            href={getLinkWithAnalytics(initialDisclosureStatement)}
            newTab
            onClick={() => trackEventSender(initialDisclosureStatement.label)}
          >
            {initialDisclosureStatement.label}
          </Link>{' '}
          form the credit contract you as a Gem Visa customer and us.
        </List.Item>
      </List>
    );
  }

  if (country === 'AU') {
    return (
      <>
        <Text margin="3 0">By starting your application you agree to:</Text>
        <List>
          <List.Item>
            The{' '}
            <Link
              href={getLinkWithAnalytics(OverviewSources.AU.latitude_gem_visa)}
              newTab
              onClick={() => trackEventSender(OverviewSources.AU.latitude_gem_visa.label)}
            >
              {OverviewSources.AU.latitude_gem_visa.label}
            </Link>{' '}
            meets your key credit card requirements
          </List.Item>
          <List.Item>
            Have read and understood the Privacy Notice,{' '}
            <Link
              href={getLinkWithAnalytics(OverviewSources.AU.key_facts_sheet)}
              newTab
              onClick={() => trackEventSender(OverviewSources.AU.key_facts_sheet.label)}
            >
              {OverviewSources.AU.key_facts_sheet.label}
            </Link>{' '}
            and{' '}
            <Link
              href={getLinkWithAnalytics(OverviewSources.AU.gem_conditions_of_use)}
              newTab
              onClick={() => trackEventSender(OverviewSources.AU.gem_conditions_of_use.label)}
            >
              {OverviewSources.AU.gem_conditions_of_use.label}
            </Link>{' '}
            and Declaration and Credit Application. These documents will provide an understanding of how your personal
            information is used, the product’s ket facts and terms and conditions under which this product operates
          </List.Item>
          <List.Item>Let us complete a credit check during your application</List.Item>
          <List.Item>Receive your financial table and credit documents electronically</List.Item>
          <List.Item>
            Electronic income verification being attempted by our service providers, including Verifier (see Verifier’s
            terms of service). If your income can’t be verified via these means, you’ll be provided an alternative
            option.
          </List.Item>
        </List>
      </>
    );
  }
}
