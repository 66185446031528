import React from 'react';
import { Button, Container, useTheme, Text } from 'basis';
import { byCard } from 'utils/byConfig';
import { PRODUCT_CODE_AFS, PRODUCT_CODE_AU_GEM, PRODUCT_CODE_GO } from '_config';
import { HeaderBannerTextStyle, ProductNameWrapper, ProductSubtitle } from './LandingPageAu.styles';

const HeaderBannerTextArea = ({ openFeatureAndBenefitsModal }) => {
  const theme = useTheme();
  return (
    <HeaderBannerTextStyle theme={theme}>
      <Container
        padding="0 4 8 4"
        padding-xs="0 6 0 5"
        padding-md="0 0 0 0"
        margin="0"
        margin-md="0 0 0 5"
        margin-lg="0 0 0 5"
        margin-xl="0 0 0 0"
        width="100%"
      >
        <ProductNameWrapper>
          {byCard({
            [PRODUCT_CODE_AU_GEM]: 'Apply for a Latitude Gem Visa credit card',
            [PRODUCT_CODE_GO]: 'Apply for a Latitude GO Mastercard credit card',
            [PRODUCT_CODE_AFS]: 'Apply for a CreditLine credit card',
            default: '',
          })}
          <br />
        </ProductNameWrapper>
        <ProductSubtitle>
          <Text margin="4 0" color="white" textStyle="subtitle1">
            {byCard({
              [PRODUCT_CODE_AU_GEM]: 'Shop today with more time to pay.*',
              [PRODUCT_CODE_GO]: 'Break up your bigger buys at hundreds of Australia’s biggest retailers.*',
              default: '',
            })}
          </Text>
        </ProductSubtitle>
        <Button margin="0 0 8 0" variant="secondary" onClick={openFeatureAndBenefitsModal}>
          View features & benefits
        </Button>
      </Container>
    </HeaderBannerTextStyle>
  );
};

export { HeaderBannerTextArea };
